import axios from 'axios'

export function apiLogin({ pwd }) {
  return axios({
    method: 'post',
    url: '/admin-api/login',
    data: { pwd }
  })
}

export function uploadImage(fileInfo) {
  const form = new FormData()
  form.append('file', fileInfo.file)
  return axios({
    method: 'post',
    url: '/admin-api/uploadImage',
    data: form
  })
}

export function listDiary() {
  console.log(window.localStorage.getItem('token'))
  return axios({
    method: 'post',
    url: '/admin-api/listDiary'
  })
}

export function addDiary({ content, imageList }) {
  return axios({
    method: 'post',
    url: '/admin-api/addDiary',
    data: { content, imageList }
  })
}

export function modifyDiary({ id, content, imageList }) {
  return axios({
    method: 'post',
    url: '/admin-api/modifyDiary',
    data: { id, content, imageList }
  })
}

export function deleteDiary({ id }) {
  return axios({
    method: 'post',
    url: '/admin-api/deleteDiary',
    data: { id }
  })
}

export function getSwipeImage() {
  return axios({
    method: 'post',
    url: '/admin-api/getSwipeImage'
  })
}

export function getAppConfig() {
  return axios({
    method: 'post',
    url: '/admin-api/getAppConfig'
  })
}

export function modifyAppConfig({ pushTime, location, pushSwitch, uploadSwitch }) {
  return axios({
    method: 'post',
    url: '/admin-api/modifyAppConfig',
    data: { pushTime, location, pushSwitch, uploadSwitch }
  })
}

export function apiListScheduleTask() {
  return axios({
    method: 'post',
    url: '/admin-api/schedule/list'
  })
}

export function apiAddScheduleTask({ taskIcon, taskName, startDate, taskType, enableFlag }) {
  return axios({
    method: 'post',
    url: '/admin-api/schedule/add',
    data: { taskIcon, taskName, startDate, taskType, enableFlag }
  })
}

export function apiModifyScheduleTask({ id, taskIcon, taskName, startDate, taskType, enableFlag }) {
  return axios({
    method: 'post',
    url: '/admin-api/schedule/modify',
    data: { id, taskIcon, taskName, startDate, taskType, enableFlag }
  })
}

export function apiDeleteScheduleTask({ id }) {
  return axios({
    method: 'post',
    url: '/admin-api/schedule/delete',
    data: { id }
  })
}

export function apiForcePush() {
  return axios({
    method: 'post',
    url: '/admin-api/forcePush'
  })
}

export function apiPushMsg({ content, target }) {
  return axios({
    method: 'post',
    url: '/admin-api/push/pushMsg',
    data: { content, target }
  })
}


export function apiListPushTask() {
  return axios({
    method: 'post',
    url: '/admin-api/push/list'
  })
}

export function apiAddPushTask({ title, content, target, startDate, startTime, taskType, enableFlag }) {
  return axios({
    method: 'post',
    url: '/admin-api/push/add',
    data: { title, content, target, startDate: startDate + ' ' + startTime[0] + ':' + startTime[1], startTime, taskType, enableFlag }
  })
}

export function apiModifyPushTask({ id, title, content, target, startDate, startTime, taskType, enableFlag }) {
  return axios({
    method: 'post',
    url: '/admin-api/push/modify',
    data: { id, title, content, target, startDate: startDate + ' ' + startTime[0] + ':' + startTime[1], startTime, taskType, enableFlag }
  })
}

export function apiDeletePushTask({ id }) {
  return axios({
    method: 'post',
    url: '/admin-api/push/delete',
    data: { id }
  })
}