import { getAppConfig, modifyAppConfig, apiForcePush, apiPushMsg } from '@/utils';
import { showNotify } from 'vant';
import { showConfirmDialog } from 'vant';
import { showLoadingToast, closeToast } from 'vant';
export default {
  data() {
    return {
      show: false,
      timePicker: {
        show: false
      },
      loading: false,
      config: {
        location: '-',
        pushSwitch: false,
        pushTime: ['00', '00'],
        uploadSwitch: false
      },
      actions: [{
        name: '北京',
        value: 'beijing',
        callback: this.onChangeLocation
      }, {
        name: '广州',
        value: 'guangzhou',
        callback: this.onChangeLocation
      }, {
        name: '崇左',
        value: 'chongzuo',
        callback: this.onChangeLocation
      }],
      pushForm: {
        loading: false,
        show: false,
        showAction: false,
        actions: [{
          name: '公主',
          value: 'mzy',
          callback: this.onChangePushTarget
        }, {
          name: '熊熊',
          value: 'lsx',
          callback: this.onChangePushTarget
        }],
        content: '',
        name: '',
        target: ''
      }
    };
  },
  created() {
    this.doInit(true);
  },
  methods: {
    doInit(showToast) {
      if (showToast) {
        showLoadingToast({
          message: '加载中...',
          forbidClick: true,
          overlay: true,
          duration: 0
        });
      }
      getAppConfig().then(res => {
        console.log(res);
        const {
          data
        } = res.data || {};
        this.config = data;
        this.loading = false;
        closeToast();
      }).catch(() => {
        this.loading = false;
        closeToast();
      });
    },
    doSetPushTime() {
      console.log(this.config);
      this.timePicker.show = false;
      this.onModifyConfig();
    },
    onChangeLocation(item) {
      this.config.location = item.value;
      this.onModifyConfig();
    },
    getLocationName(location) {
      const mp = {
        guangzhou: '广州',
        beijing: '北京',
        nanning: '南宁',
        chongzuo: '崇左'
      };
      return mp[location] || location;
    },
    onModifyConfig() {
      modifyAppConfig(this.config).then(res => {
        const {
          data
        } = res.data;
        showNotify({
          type: 'success',
          message: '设置更新成功'
        });
        this.config = data;
      });
    },
    onForcePush() {
      showConfirmDialog({
        title: '手动推送',
        message: '确认进行手动推送'
      }).then(() => {
        // on confirm
        showLoadingToast({
          message: '推送中...',
          forbidClick: true,
          overlay: true,
          duration: 0
        });
        apiForcePush().then(() => {
          closeToast();
          showNotify({
            type: 'success',
            message: ' 提交成功'
          });
        }).catch(err => {
          closeToast();
          showNotify({
            type: 'danger',
            message: ' 提交失败' + err
          });
        });
      });
    },
    onPushMsg() {
      let person = window.localStorage.getItem('person');
      let target;
      let name = '公主';
      if (person === 'lsx') {
        name = '公主';
        target = 'mzy';
      } else {
        person = 'mzy';
        target = 'lsx';
        name = '熊熊';
      }
      this.pushForm.name = name;
      this.pushForm.target = target;
      this.pushForm.content = '';
      this.pushForm.show = true;
    },
    onClickLeft() {
      this.pushForm.show = false;
    },
    onChangePushTarget(ev) {
      console.log(ev);
      this.pushForm.name = ev.name;
      this.pushForm.target = ev.value;
    },
    doPushMsg() {
      console.log(this.pushForm);
      this.pushForm.loading = true;
      apiPushMsg(this.pushForm).then(res => {
        this.pushForm.loading = false;
        console.log(res);
        showNotify({
          type: 'success',
          message: '手机推送成功'
        });
        this.pushForm.show = false;
      }).catch(() => {
        this.pushForm.loading = false;
      });
    },
    onLogout() {
      this.loading = true;
      window.localStorage.setItem('token', '');
      this.doInit(false);
    }
  }
};