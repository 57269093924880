import "core-js/modules/es.array.push.js";
import { getSwipeImage, uploadImage, listDiary, addDiary, modifyDiary, deleteDiary } from '@/utils';
import { showConfirmDialog, showDialog, showNotify } from 'vant';
import { ref } from 'vue';
export default {
  data() {
    return {
      loading: false,
      content: '',
      images: [],
      diaryList: [],
      fileList: [],
      form: {
        show: false,
        mode: 'add',
        id: '',
        content: '',
        imageList: []
      }
    };
  },
  created() {
    this.onGetDiaryList();
  },
  methods: {
    onAdd() {
      this.form.mode = 'add';
      this.form.content = '';
      this.form.imageList = [];
      this.fileList = [];
      this.form.show = true;
    },
    onGetDiaryList() {
      this.images = [];
      getSwipeImage().then(res => {
        const {
          data
        } = res.data;
        for (const e of data || []) {
          this.images.push({
            url: '/admin-api/previewImage/' + e
          });
        }
      });
      listDiary().then(resp => {
        const {
          data
        } = resp.data;
        this.diaryList = data || [];
      });
    },
    onEdit(item) {
      this.form.mode = 'edit';
      this.form.id = item.id;
      this.form.content = item.content;
      this.form.imageList = item.imageList;
      const arr = [];
      for (const e of item.imageList) {
        console.log(e);
        arr.push({
          id: e,
          url: '/admin-api/previewImage/' + e,
          isImage: true,
          status: 'done'
        });
      }
      this.fileList = ref(arr);
      this.form.show = true;
    },
    onClickLeft() {
      console.log('left');
      this.form.show = false;
    },
    onClickRight() {
      showConfirmDialog({
        title: '确认删除',
        confirmButtonText: '删除',
        confirmButtonColor: '#ee0a24',
        message: '操作不可恢复，请确认'
      }).then(() => {
        // on confirm
        deleteDiary(this.form).then(() => {
          showNotify({
            type: 'success',
            message: '删除成功'
          });
          this.form.show = false;
          this.onGetDiaryList();
        });
      }).catch(() => {
        // on cancel
      });
    },
    onSubmit() {
      console.log(this.fileList);
      for (const item of this.fileList) {
        if (item.status !== 'done') {
          showDialog({
            title: '',
            message: '请等待所有图片上传完毕再提交'
          });
          return;
        }
      }
      this.form.imageList = this.fileList;
      this.loading = true;
      if (this.form.mode === 'add') {
        addDiary(this.form).then(resp => {
          this.loading = false;
          const {
            code
          } = resp.data;
          if (code === 200) {
            showNotify({
              type: 'success',
              message: '修改成功'
            });
            this.onGetDiaryList();
            this.form.show = false;
          }
        });
      } else {
        modifyDiary(this.form).then(resp => {
          this.loading = false;
          const {
            code
          } = resp.data;
          if (code === 200) {
            showNotify({
              type: 'success',
              message: '修改成功'
            });
            this.onGetDiaryList();
            this.form.show = false;
          }
        });
      }
    },
    afterRead(fileInfo) {
      console.log(fileInfo);
      if (fileInfo instanceof Array) {
        for (const e of fileInfo) {
          const item = e;
          item.status = 'uploading';
          uploadImage(item).then(res => {
            item.status = 'done';
            const {
              data
            } = res.data;
            console.log(data);
            const {
              id,
              url,
              media_id
            } = data;
            item.id = id;
            item.url = url;
            item.media_id = media_id;
          });
        }
      } else {
        fileInfo.status = 'uploading';
        uploadImage(fileInfo).then(res => {
          fileInfo.status = 'done';
          const {
            data
          } = res.data;
          console.log(data);
          const {
            id,
            url,
            media_id
          } = data;
          fileInfo.id = id;
          fileInfo.url = url;
          fileInfo.media_id = media_id;
        });
      }
    }
  }
};