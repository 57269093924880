import { showNotify } from 'vant';
import { apiListScheduleTask, apiAddScheduleTask, apiModifyScheduleTask } from '@/utils';
export default {
  data() {
    return {
      loading: false,
      submitLoading: false,
      show: false,
      typeList: [{
        value: 'single',
        text: '单次'
      }, {
        value: 'daily',
        text: '每日'
      }, {
        value: 'weekly',
        text: '每周'
      }, {
        value: 'monthly',
        text: '每月'
      }, {
        value: 'yearly',
        text: '每年'
      }],
      addForm: {
        show: false,
        showPicker: false,
        showCalendar: false,
        mode: 'add',
        id: '',
        taskIcon: '',
        taskName: '',
        taskTypeName: '',
        taskType: '',
        startDate: '',
        enableFlag: true
      },
      list: []
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      apiListScheduleTask().then(res => {
        this.loading = false;
        const {
          data
        } = res.data;
        this.list = data;
      });
    },
    getTypeName(code) {
      if (!code) return '';
      const mp = {
        single: '单次',
        daily: '每日',
        weekly: '每周',
        monthly: '每月',
        yearly: '每年'
      };
      return mp[code] || '';
    },
    onAdd() {
      this.addForm.taskIcon = '';
      this.addForm.taskName = '';
      this.addForm.taskTypeName = '';
      this.addForm.taskType = '';
      this.addForm.startDate = '';
      this.addForm.mode = 'add';
      this.addForm.show = true;
    },
    onClickLeft() {
      this.addForm.show = false;
    },
    onChangeSwitch() {},
    onConfirmTaskType(row) {
      console.log(row);
      this.addForm.taskType = row.selectedOptions[0].value;
      this.addForm.taskTypeName = row.selectedOptions[0].text;
      this.addForm.showPicker = false;
    },
    onConfirmDate(date) {
      console.log(date);
      this.addForm.startDate = this.formatDate(date);
      this.addForm.showCalendar = false;
    },
    onSubmit() {
      console.log(this.addForm);
      if (this.addForm.mode === 'add') {
        this.addForm.loading = true;
        apiAddScheduleTask(this.addForm).then(() => {
          this.addForm.loading = false;
          showNotify({
            type: 'success',
            message: '新建成功'
          });
          this.fetchData();
          this.addForm.show = false;
        }).catch(() => {
          this.addForm.loading = false;
        });
      } else {
        this.addForm.loading = true;
        apiModifyScheduleTask(this.addForm).then(() => {
          this.addForm.loading = false;
          showNotify({
            type: 'success',
            message: '修改成功'
          });
          this.fetchData();
          this.addForm.show = false;
        }).catch(() => {
          this.addForm.loading = false;
        });
      }
    },
    onEdit(item) {
      console.log(item);
      const {
        id,
        taskIcon,
        taskName,
        taskType,
        startDate,
        enableFlag
      } = item;
      this.addForm.id = id;
      this.addForm.taskIcon = taskIcon;
      this.addForm.taskName = taskName;
      this.addForm.taskType = taskType;
      this.addForm.startDate = startDate;
      this.addForm.enableFlag = enableFlag;
      this.addForm.mode = 'edit';
      this.addForm.show = true;
    },
    formatDate(date) {
      var y = date.getFullYear();
      console.log(y);
      var m = date.getMonth() + 1;
      m = m < 10 ? '0' + m : m;
      var d = date.getDate();
      d = d < 10 ? '0' + d : d;
      return y + '-' + m + '-' + d;
    }
  }
};