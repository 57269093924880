import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "masks"
};
const _hoisted_2 = {
  class: "gesturePwd"
};
const _hoisted_3 = {
  style: {
    "position": "absolute",
    "top": "0",
    "left": "0",
    "right": "0",
    "bottom": "0"
  }
};
const _hoisted_4 = {
  ref: "canvas",
  style: {
    "background-color": "#fff",
    "display": "inline-block",
    "margin-top": "76px",
    "width": "320px",
    "height": "320px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("h4", {
    ref: "gestureTitle",
    class: "gestureTitle"
  }, _toDisplayString($data.title), 513), _createElementVNode("canvas", _hoisted_4, null, 512)])])]);
}