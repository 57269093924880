import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_HandPassword = _resolveComponent("HandPassword");
  const _component_van_nav_bar = _resolveComponent("van-nav-bar");
  const _component_MainHome = _resolveComponent("MainHome");
  const _component_ScheduleTask = _resolveComponent("ScheduleTask");
  const _component_PushTask = _resolveComponent("PushTask");
  const _component_MainSettings = _resolveComponent("MainSettings");
  const _component_van_dialog = _resolveComponent("van-dialog");
  const _component_van_notify = _resolveComponent("van-notify");
  const _component_van_tabbar_item = _resolveComponent("van-tabbar-item");
  const _component_van_tabbar = _resolveComponent("van-tabbar");
  return _openBlock(), _createElementBlock("div", null, [_withDirectives(_createElementVNode("div", null, [_withDirectives(_createVNode(_component_HandPassword, {
    "login-success": $options.loginSuccess
  }, null, 8, ["login-success"]), [[_vShow, $data.needLogin]])], 512), [[_vShow, $data.needLogin]]), _withDirectives(_createElementVNode("div", null, [_createElementVNode("div", null, [_createVNode(_component_van_nav_bar, {
    title: "麻麻咪专属推送"
  })]), _createElementVNode("div", null, [$data.active === 0 ? (_openBlock(), _createBlock(_component_MainHome, {
    key: 0
  })) : _createCommentVNode("", true), $data.active === 1 ? (_openBlock(), _createBlock(_component_ScheduleTask, {
    key: 1
  })) : _createCommentVNode("", true), $data.active === 2 ? (_openBlock(), _createBlock(_component_PushTask, {
    key: 2
  })) : _createCommentVNode("", true), $data.active === 3 ? (_openBlock(), _createBlock(_component_MainSettings, {
    key: 3
  })) : _createCommentVNode("", true)]), _createVNode(_component_van_dialog), _createVNode(_component_van_notify), _createVNode(_component_van_tabbar, {
    modelValue: $data.active,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.active = $event),
    "safe-area-inset-bottom": ""
  }, {
    default: _withCtx(() => [_createVNode(_component_van_tabbar_item, {
      icon: "home-o"
    }, {
      default: _withCtx(() => [_createTextVNode("主页")]),
      _: 1
    }), _createVNode(_component_van_tabbar_item, {
      icon: "todo-list-o"
    }, {
      default: _withCtx(() => [_createTextVNode("推送")]),
      _: 1
    }), _createVNode(_component_van_tabbar_item, {
      icon: "todo-list-o"
    }, {
      default: _withCtx(() => [_createTextVNode("提醒")]),
      _: 1
    }), _createVNode(_component_van_tabbar_item, {
      icon: "setting-o"
    }, {
      default: _withCtx(() => [_createTextVNode("设置")]),
      _: 1
    })]),
    _: 1
  }, 8, ["modelValue"])], 512), [[_vShow, !$data.needLogin]])]);
}