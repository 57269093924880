import { showNotify } from 'vant';
import { apiListPushTask, apiAddPushTask, apiModifyPushTask, apiDeletePushTask } from '@/utils';
export default {
  data() {
    return {
      loading: false,
      submitLoading: false,
      show: false,
      targetList: [{
        value: 'lsx',
        text: '🐻熊熊'
      }, {
        value: 'mzy',
        text: '👸🏻公主'
      }],
      typeList: [{
        value: 'single',
        text: '单次'
      }, {
        value: 'daily',
        text: '每日'
      }, {
        value: 'weekly',
        text: '每周'
      }],
      addForm: {
        show: false,
        showPicker: false,
        showTargetPicker: false,
        showCalendar: false,
        showTimePicker: false,
        pickerTime: ['00', '00'],
        mode: 'add',
        id: '',
        title: '',
        content: '',
        target: '',
        targetName: '',
        taskTypeName: '',
        taskType: 'single',
        startDate: '',
        startTime: '',
        enableFlag: true
      },
      list: []
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    formatter(type, option) {
      if (type === 'hour') {
        option.text += '时';
      }
      if (type === 'minute') {
        option.text += '分';
      }
      return option;
    },
    fetchData() {
      this.loading = true;
      apiListPushTask().then(res => {
        this.loading = false;
        const {
          data
        } = res.data;
        this.list = data;
      });
    },
    getTargetName(code) {
      if (!code) return '';
      const mp = {
        lsx: '🐻熊熊',
        mzy: '👸🏻公主'
      };
      return mp[code] || '';
    },
    getTypeName(code) {
      if (!code) return '';
      const mp = {
        single: '单次',
        daily: '每日',
        weekly: '每周',
        monthly: '每月',
        yearly: '每年'
      };
      return mp[code] || '';
    },
    onAdd() {
      let date = new Date();
      const arr = [date.getHours() < 10 ? '0' + date.getHours() : '' + date.getHours(), date.getMinutes() < 10 ? '0' + date.getMinutes() : '' + date.getMinutes()];
      let person = window.localStorage.getItem('person');
      this.addForm.title = '';
      this.addForm.content = '';
      this.addForm.target = person;
      this.addForm.taskType = 'single';
      this.addForm.startDate = this.formatDate(date);
      this.addForm.startTime = arr;
      this.addForm.pickerTime = arr;
      this.addForm.enableFlag = true;
      this.addForm.mode = 'add';
      this.addForm.show = true;
    },
    onClickLeft() {
      this.addForm.show = false;
    },
    onChangeSwitch() {},
    onConfirmTarget(row) {
      this.addForm.target = row.selectedOptions[0].value;
      this.addForm.targetName = row.selectedOptions[0].text;
      this.addForm.showTargetPicker = false;
    },
    onConfirmTime(row) {
      console.log(row.selectedOptions[0]);
      console.log(this.addForm.pickerTime);
      this.addForm.startTime = [row.selectedOptions[0].value, row.selectedOptions[1].value];
      this.addForm.showTimePicker = false;
    },
    onConfirmTaskType(row) {
      console.log(row);
      this.addForm.taskType = row.selectedOptions[0].value;
      this.addForm.taskTypeName = row.selectedOptions[0].text;
      this.addForm.showPicker = false;
    },
    onConfirmDate(date) {
      console.log(date);
      this.addForm.startDate = this.formatDate(date);
      this.addForm.showCalendar = false;
    },
    onSubmit() {
      console.log(this.addForm);
      if (this.addForm.mode === 'add') {
        this.addForm.loading = true;
        apiAddPushTask(this.addForm).then(() => {
          this.addForm.loading = false;
          showNotify({
            type: 'success',
            message: '新建成功'
          });
          this.fetchData();
          this.addForm.show = false;
        }).catch(() => {
          this.addForm.loading = false;
        });
      } else {
        this.addForm.loading = true;
        apiModifyPushTask(this.addForm).then(() => {
          this.addForm.loading = false;
          showNotify({
            type: 'success',
            message: '修改成功'
          });
          this.fetchData();
          this.addForm.show = false;
        }).catch(() => {
          this.addForm.loading = false;
        });
      }
    },
    onEdit(item) {
      console.log(item);
      const {
        id,
        title,
        content,
        target,
        taskType,
        startDate,
        enableFlag
      } = item;
      this.addForm.id = id;
      this.addForm.title = title;
      this.addForm.content = content;
      this.addForm.target = target;
      this.addForm.taskType = taskType;
      this.addForm.startDate = startDate.substring(0, 10);
      this.addForm.startTime = [startDate.substring(11, 13), startDate.substring(14, 16)];
      this.addForm.enableFlag = enableFlag;
      this.addForm.mode = 'edit';
      this.addForm.show = true;
    },
    onRemovePushTask() {
      console.log(this.addForm);
      apiDeletePushTask(this.addForm).then(res => {
        console.log(res);
        this.addForm.show = false;
        this.fetchData();
      });
    },
    formatTime(arr) {
      return arr[0] + '时:' + arr[1] + '分';
    },
    formatDate(date) {
      var y = date.getFullYear();
      console.log(y);
      var m = date.getMonth() + 1;
      m = m < 10 ? '0' + m : m;
      var d = date.getDate();
      d = d < 10 ? '0' + d : d;
      return y + '-' + m + '-' + d;
    }
  }
};