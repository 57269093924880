import { createApp } from 'vue'
import App from './App.vue'

import { Button } from 'vant'
import { Tabbar, TabbarItem } from 'vant'
import { NavBar } from 'vant'
import { Cell, CellGroup } from 'vant'
import { Swipe, SwipeItem } from 'vant'
import { List } from 'vant'
import { Popup } from 'vant'
import { Calendar, Form, Field } from 'vant'
import { Uploader } from 'vant'
import { Notify } from 'vant'
import { Switch } from 'vant'
import { Toast } from 'vant'
import { TimePicker } from 'vant'

createApp(App)
  .use(NavBar).use(Switch)
  .use(List).use(Popup).use(Field).use(Form).use(Uploader)
  .use(Cell).use(CellGroup).use(Swipe).use(SwipeItem).use(Notify)
  .use(Button).use(Calendar).use(TimePicker)
  .use(Tabbar).use(Toast)
  .use(TabbarItem)
  .mount('#app')
