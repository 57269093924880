import "core-js/modules/es.array.push.js";
import { apiLogin } from '@/utils';
import axios from 'axios';
export default {
  props: {
    loginSuccess: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      title: '请绘制您的图形密码',
      currentValue: false,
      ctx: '',
      width: 0,
      height: 0,
      devicePixelRatio: 0,
      r: '',
      // 公式计算
      lastPoint: [],
      arr: [],
      restPoint: [],
      pswObj: {
        step: 2
      },
      canvas: '',
      endPo: ''
    };
  },
  watch: {
    value: {
      handler: function (val) {
        this.currentValue = val;
      },
      immediate: true
    },
    currentValue(val) {
      this.$emit(val ? 'on-show' : 'on-hide');
      this.$emit('input', val);
    }
  },
  created() {
    if (typeof this.value !== 'undefined') {
      this.currentValue = this.value;
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    H5lock(obj) {
      this.height = obj.height;
      this.width = obj.width;
      this.devicePixelRatio = window.devicePixelRatio || 1;
    },
    init() {
      this.initDom();
      this.lastPoint = [];
      this.touchFlag = false;
      this.canvas = this.$refs.canvas;
      this.ctx = this.canvas.getContext('2d');
      this.createCircle();
      this.bindEvent();
    },
    drawCle(x, y) {
      // 初始化解锁密码面板 小圆圈
      this.ctx.strokeStyle = '#87888a'; // 密码的点点默认的颜色
      this.ctx.lineWidth = 3;
      this.ctx.beginPath();
      this.ctx.arc(x, y, this.r, 0, Math.PI * 2, true);
      this.ctx.closePath();
      this.ctx.stroke();
    },
    drawPoint(style) {
      // 初始化圆心
      for (var i = 0; i < this.lastPoint.length; i++) {
        this.ctx.fillStyle = style;
        this.ctx.beginPath();
        this.ctx.arc(this.lastPoint[i].x, this.lastPoint[i].y, this.r / 2.5, 0, Math.PI * 2, true);
        this.ctx.closePath();
        this.ctx.fill();
      }
    },
    drawStatusPoint(type) {
      // 初始化状态线条
      for (var i = 0; i < this.lastPoint.length; i++) {
        this.ctx.strokeStyle = type;
        this.ctx.beginPath();
        this.ctx.arc(this.lastPoint[i].x, this.lastPoint[i].y, this.r, 0, Math.PI * 2, true);
        this.ctx.closePath();
        this.ctx.stroke();
      }
    },
    drawLine(style, po) {
      // style:颜色 解锁轨迹
      this.ctx.beginPath();
      this.ctx.strokeStyle = style;
      this.ctx.lineWidth = 3;
      this.ctx.moveTo(this.lastPoint[0].x, this.lastPoint[0].y);
      for (var i = 1; i < this.lastPoint.length; i++) {
        this.ctx.lineTo(this.lastPoint[i].x, this.lastPoint[i].y);
      }
      this.ctx.lineTo(po.x, po.y);
      this.ctx.stroke();
      this.ctx.closePath();
    },
    createCircle() {
      // 创建解锁点的坐标，根据canvas的大小来平均分配半径

      var n = 3;
      var count = 0;
      this.r = this.ctx.canvas.width / 12; // 公式计算
      this.lastPoint = [];
      this.arr = [];
      this.restPoint = [];
      var r = this.r;
      for (var i = 0; i < n; i++) {
        for (var j = 0; j < n; j++) {
          count++;
          var obj = {
            x: (j * 4 + 2) * r,
            y: i * 4 * r + 2 * r,
            index: count
          };
          this.arr.push(obj);
          this.restPoint.push(obj);
        }
      }
      this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
      for (let i = 0; i < this.arr.length; i++) {
        this.drawCle(this.arr[i].x, this.arr[i].y);
      }

      // return arr;
    },

    getPosition(e) {
      // 获取touch点相对于canvas的坐标
      var rect = e.currentTarget.getBoundingClientRect();
      var po = {
        x: (e.touches[0].clientX - rect.left) * this.devicePixelRatio,
        y: (e.touches[0].clientY - rect.top) * this.devicePixelRatio
      };
      return po;
    },
    update(po) {
      // 核心变换方法在touchmove时候调用
      this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
      for (var i = 0; i < this.arr.length; i++) {
        // 每帧先把面板画出来
        this.drawCle(this.arr[i].x, this.arr[i].y);
      }
      this.drawPoint('#27AED5'); // 每帧花轨迹
      this.drawStatusPoint('#27AED5'); // 每帧花轨迹

      this.endPo = po;
      this.drawLine('#27AED5', po, this.lastPoint); // 每帧画圆心

      for (let i = 0; i < this.restPoint.length; i++) {
        if (Math.abs(po.x - this.restPoint[i].x) < this.r && Math.abs(po.y - this.restPoint[i].y) < this.r) {
          this.drawPoint(this.restPoint[i].x, this.restPoint[i].y);
          this.lastPoint.push(this.restPoint[i]);
          this.restPoint.splice(i, 1);
          break;
        }
      }
    },
    checkPass(arr) {
      // touchend结束之后对密码和状态的处理
      let str = '';
      for (const i of arr) {
        str += i.index;
      }
      console.log(str);
      // 执行登录
      apiLogin({
        pwd: str
      }).then(res => {
        console.log(res);
        const {
          data
        } = res.data;
        this.title = '登录成功';
        this.drawStatusPoint('#2CFF26');
        this.drawPoint('#2CFF26');
        this.drawLine('#2CFF26', this.endPo); // 每帧画圆心

        window.localStorage.setItem('person', data.split('#')[0]);
        window.localStorage.setItem('token', data);
        axios.defaults.headers.common['X-Token'] = data;
        setTimeout(() => {
          this.loginSuccess && this.loginSuccess();
        }, 800);
      }).catch(err => {
        const data = err.data;
        // error
        this.title = data.message;
        this.drawStatusPoint('red');
        this.drawPoint('red');
        this.drawLine('red', this.endPo); // 每帧画圆心
      });
    },

    initDom() {
      this.devicePixelRatio = window.devicePixelRatio || 1;
      var canvas = this.$refs.canvas;
      var width = this.width || 320;
      var height = this.height || 320;

      // 高清屏锁放
      canvas.style.width = width + 'px';
      canvas.style.height = height + 'px';
      canvas.height = height * this.devicePixelRatio;
      canvas.width = width * this.devicePixelRatio;
    },
    reset() {
      this.title = '请绘制您的图形密码';
      this.createCircle();
    },
    bindEvent() {
      var self = this;
      this.canvas = this.$refs.canvas;
      this.canvas.addEventListener('touchstart', function (e) {
        e.preventDefault(); // 某些android 的 touchmove不宜触发 所以增加此行代码
        var po = self.getPosition(e);
        for (var i = 0; i < self.arr.length; i++) {
          if (Math.abs(po.x - self.arr[i].x) < self.r && Math.abs(po.y - self.arr[i].y) < self.r) {
            self.touchFlag = true;
            self.drawPoint(self.arr[i].x, self.arr[i].y);
            self.lastPoint.push(self.arr[i]);
            self.restPoint.splice(i, 1);
            break;
          }
        }
      }, false);
      this.canvas.addEventListener('touchmove', function (e) {
        if (self.touchFlag) {
          self.update(self.getPosition(e));
        }
      }, false);
      this.canvas.addEventListener('touchend', function () {
        if (self.touchFlag) {
          self.touchFlag = false;
          self.checkPass(self.lastPoint);
          setTimeout(function () {
            self.reset();
          }, 1000);
        }
      }, false);
    }
  }
};