import MainHome from './components/MainHome';
import MainSettings from './components/MainSettings';
import ScheduleTask from './components/ScheduleTask';
import PushTask from './components/PushTask';
import HandPassword from './components/HandPassword';
import axios from 'axios';
import { showNotify } from 'vant';

// import { Dialog } from 'vant';
export default {
  name: 'App',
  components: {
    MainHome,
    ScheduleTask,
    PushTask,
    MainSettings,
    HandPassword
  },
  data() {
    return {
      active: 0,
      needLogin: false
    };
  },
  created() {
    console.log(this);
    const that = this;
    // 添加响应拦截器
    axios.interceptors.response.use(function (response) {
      // 对响应数据做点什么
      const {
        data
      } = response;
      if (data.code === 401) {
        that.showLogin();
        return Promise.reject(response);
      }
      if (data.code !== 200) {
        showNotify({
          type: 'danger',
          message: data.message
        });
        return Promise.reject(response);
      }
      return response;
    });

    // 注册登录拦截器
    axios.interceptors.request.use(function (config) {
      config.headers['X-Token'] = window.localStorage.getItem('token');
      return config;
    });
  },
  methods: {
    loginSuccess() {
      this.needLogin = false;
    },
    showLogin() {
      this.needLogin = true;
    }
  }
};